import { createMuiTheme, responsiveFontSizes } from '@material-ui/core';

let Theme = createMuiTheme({
    palette: {
        secondary: {
            light: '#975bc2',
            main: '#662e91',
            dark: '#370063'
        },
        primary: {
            light: '#ff7dbc',
            main: '#ff458c',
            dark: '#c7005f'
        }
    },
    typography: {
        fontFamily: "'Montserrat', sans-serif"
    }
})

Theme = responsiveFontSizes(Theme)

export default Theme
