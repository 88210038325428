import React, { Suspense, lazy, useEffect, useState } from 'react';
import App from './App'
import * as serviceWorkerRegistration from './serviceWorkerRegistration';
import { useTranslation } from 'react-i18next'
import { Snackbar, Button } from '@material-ui/core'
import { Alert } from '@material-ui/lab'

function SwUpdate(props) {
    const [newVersionAvailable, setNewVersionAvailable] = useState(false)
    const [waitingWorker, setWaitingWorker] = useState({})
    const [test, setTest] = useState(true)
    const { t } = useTranslation('language')
    // const isMobileWidth = window.innerWidth > 960 ? false: true
    // Console(window.innerHeight)



    const onServiceWorkerUpdate = (registration) => {
        console.log('update pending and updating')
        registration && setWaitingWorker(registration.waiting)
        setNewVersionAvailable(true)
    };

    const updateServiceWorker = () => {
        // const { waitingWorker } = this.state;
        // console.log(waitingWorker)
        waitingWorker && waitingWorker.postMessage({ type: "SKIP_WAITING" });
        setNewVersionAvailable(false)
        window.location.reload();
    };

    React.useEffect(() => {
        if (process.env.NODE_ENV === 'production') {
            console.log('Registering service worker for update')
            serviceWorkerRegistration.register({ onUpdate: onServiceWorkerUpdate });
        }

    }, [])

    return (
        <>

            <App />
            <Snackbar open={newVersionAvailable} anchorOrigin={{ vertical: 'top', horizontal: 'center' }} message={t("version", "New Version Available")}
                action={
                    <>
                        <Button onClick={updateServiceWorker} color='inherit'>{t("refresh", "Refresh")}</Button>
                    </>
                } />

        </>
    );
}

export default SwUpdate;


