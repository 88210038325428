import { combineReducers } from 'redux';
import {LOGOUT} from '../Constants'
import moduleReducer from './moduleReducer'
import topicReducer from './topicReducer'
import articleReducer from './articleReducer'
import lngReducer from './lngReducer'
import userReducer from './userReducer'
import userRoleReducer from './userRoleReducer';
import commentReducer from './commentReducer';
import authorReducer from './authorReducer';
import questionReducer from './questionReducer';
import isFetchedReducer from './isFetchedReducer'
import notificationReducer from './notificationReducer';
import aboutReducer from './aboutReducer';
import helplineReducer from './helplineReducer'
import adminDataReducer from './adminDataReducer'
import limitedArticleReducer from './limitedArticleReducer'
import featureArticleReducer from './featruedArticleReducer';
import moduleIdReducer from './moduleIdReducer';
import faqModuleReducer from './faqModuleReducer'
import faqArticleReducer from './faqArticleReducer'
import uidReducer from './uidReducer';
import allArticleReducer from './allArticleReducer'
import noUserReducer from './noUserReducer';
import blockedUserReducer from './blockedUserReducer';
import providerSignUpReducer from './providerSignUpReducer';
import aboutAppReducer from './aboutAppReducer';

const appReducers = combineReducers({
    modules: moduleReducer,
    topics: topicReducer,
    articles: articleReducer,
    lngCode: lngReducer,
    userDetails: userReducer,
    userRole: userRoleReducer,
    comments: commentReducer,
    authors: authorReducer,
    questions: questionReducer,
    isFetched: isFetchedReducer,
    notifications: notificationReducer,
    about: aboutReducer,
    moduleById: moduleIdReducer,
    helplines: helplineReducer,
    adminData: adminDataReducer,
    limitedArticles: limitedArticleReducer,
    featuredArticles: featureArticleReducer,
    faqModule: faqModuleReducer,
    articleFaq: faqArticleReducer,
    uid: uidReducer,
    allArticles: allArticleReducer,
    noUser: noUserReducer,
    blockedUser: blockedUserReducer,
    providerSignUp:providerSignUpReducer,
    aboutApp:aboutAppReducer
})

const allReducers = (state,action) => {
    if(action.type === LOGOUT){
        state = undefined
    }
    return appReducers(state,action)
}

export default allReducers