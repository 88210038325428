import { FETCH_ABOUT_APP } from '../Constants'

const initialState = {}

function aboutAppReducer(state = initialState, action) {
    switch (action.type) {
        case FETCH_ABOUT_APP:
            return action.payload
        default:
            return state
    }
}

export default aboutAppReducer