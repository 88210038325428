export const FETCH_MODULES = 'FETCH_MODULES'
export const FETCH_TOPICS = 'FETCH_TOPICS'
export const FETCH_ARTICLES = 'FETCH_ARTICLES'
export const LANGUAGE_CHANGE = 'LANGUAGE_CHANGE'
export const USER_DETAILS = 'USER_DETAILS'
export const USER_ROLE = 'USER_ROLE'
export const FETCH_COMMENTS = 'FETCH_COMMENTS'
export const FETCH_AUTHORS = 'FETCH_AUTHORS'
export const FETCH_QUESTIONS = 'FETCH_QUESTIONS'
export const USER_UPDATE =  'USER_UPDATE'
export const IS_FETCHED = 'IS_FETCHED'
export const LOGOUT = 'LOGOUT'
export const FETCH_NOTIFICATIONS = 'FETCH_NOTIFICATIONS'
export const FETCH_ABOUT = 'FETCH_ABOUT'
export const FETCH_ADMIN_DATA = "FETCH_ADMIN_DATA"
export const FETCH_WHATSAPP = 'FETCH_WHATSAPP'
export const FETCH_EMAIL = 'FETCH_EMAIL'
export const FETCH_HELPLINE = 'FETCH_HELPLINE'
export const LIMITED_ARTICLE_FETCH = 'LIMITED_ARTICLE_FETCH'
export const LIMITED_ARTICLE_ADD_FETCH = 'LIMITED_ARTICLE_ADD_FETCH'
export const FETCH_FEATURED_ARTICLES = 'FETCH_FEATURED_ARTICLES'
export const FETCH_ARTICLES_BY_MODULE = 'FETCH_ARTICLES_BY_MODULE'
export const FETCH_ARTICLES_BY_TOPICS = 'FETCH_ARTICLES_BY_TOPIC'
export const FETCH_MODULE_BY_ID = 'FETCH_MODULE_BY_ID'
export const FETCH_TOPIC_BY_ID = 'FETCH_TOPIC_BY_ID'
export const FETCH_ARTICLE_BY_ID = 'FETCH_ARTICLE_BY_ID'
export const RESET_MODULE = 'RESET_MODULE'
export const RESET_TOPIC = 'RESET_TOPIC'
export const RESET_ARTICLE = 'RESET_ARTICLE'
export const RESET_AUTHORS = 'RESET_AUTHORS'
export const RESET_LIMITED_ARTICLES = 'RESET_LIMITED_ARTICLES'
export const RESET_COMMENTS = 'RESET_COMMENTS'
export const RESET_QUESTIONS = 'RESET_QUESTIONS'
export const FETCH_MODULES_FAQ = 'FETCH_MODULES_FAQ'
export const FETCH_ARTICLES_FAQ = 'FETCH_ARTICLES_FAQ'
export const FETCH_ARTICLES_FAQ_ADDED = 'FETCH_ARTICLES_FAQ_ADDED'
export const RESET_FAQ_ARTICLES = 'RESET_FAQ_ARTCILES'
export const FECTH_UID = 'FETCH_UID'
export const SET_NO_USER = 'SET_NO_USER'
export const SET_BLOCKED_USER = 'SET_BLOCKED_USER'
export const PROVIDER_SIGNUP = 'PROVIDER_SIGNUP'
export const ERROR_LOGIN = 'ERROR_LOGIN'
export const FETCH_ABOUT_APP = 'FETCH_ABOUT_APP'
export const USER_TOKEN_PATCH =  'USER_TOKEN_PATCH'